import styles from "./PoliJobsProposal.module.scss";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { Avatar, Button, Row, Space, Spin, Tabs, Upload } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import {
	getUserId,
	isUserLogged,
	useIsPoliJobsAdmin,
	useIsStudent,
} from "../../utils/utilFunctions";
import { ErrorCodes, InternType, PoliJobsProposalDTO } from "../../Api";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useQueryClient, useQuery } from "react-query";
import {
	getErrorFromResponse,
	useErrorCodeTranslation,
} from "../../utils/responseUtils";
import {
	getAllApplicationTypesCount,
	getPoliJobsProposalRecommendations,
	getSpecificPoliJobsProposal,
} from "../../utils/reactQueriesConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DashboardLayout from "../../layouts/Dashboard";
import { ArrowDownOutlined } from "@ant-design/icons";
import {
	deletePoliJobsProposal,
	downloadPoliJobsProposalJD,
	getPoliJobsProposalById,
	uploadPoliJobsProposalJD,
} from "../../Requests/polijobs-proposals-requests";
import { downloadFile } from "../../utils/downloadUtils";
import PoliJobsRecommendationsTable from "./PoliJobsRecommendationsTable";
import PoliJobsRecommendationsGenerationModal from "./PoliJobsRecommendationsGenerationModal";
import { Routes } from "../../utils/routes";
import {
	applyPoliJobsProposal,
	canApply,
} from "../../Requests/polijobs-proposal-enrollment-requests";
import TabPane from "antd/es/tabs/TabPane";
import PoliJobsApplicantsTable from "./PoliJobsApplicantsTable";
import PoliJobsProposalActions from "./PoliJobsProposalActions";

const PoliJobsProposal = () => {
	return (
		<DashboardLayout>
			<ProposalPageContent />
		</DashboardLayout>
	);
};

const ProposalPageContent = () => {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const location = useLocation<
		| {
				origin: string;
		  }
		| null
		| undefined
	>();
	const [applicable, setApplicable] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [activeTab, setActiveTab] = useState("1");
	const isPoliJobsAdmin = useIsPoliJobsAdmin();
	const isStudent = useIsStudent();
	const history = useHistory();
	const queryClient = useQueryClient();
	const [errorCode, setErrorCode] = useState<ErrorCodes | null>(
		ErrorCodes.CannotApplyAgain
	);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);

	if (!id) {
		history.push("/");
	}

	const openGetErrorNotification = (_error: any) => {
		openNotification(
			t("polijobs.error"),
			t("polijobs.fetchDataError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data: poliJobsProposal } = useQuery(
		[getSpecificPoliJobsProposal, id],
		() => getPoliJobsProposalById(id),
		{
			onError: (err) => {
				history.push("/404");
				openGetErrorNotification(err);
			},
		}
	);

	const invalidateRecommendationsQuery = async () => {
		await queryClient.invalidateQueries(getPoliJobsProposalRecommendations);
	};

	const canApplyToPoliJobsProposal = () => {
		if (!poliJobsProposal) return false;

		canApply(poliJobsProposal?.id!)
			.then(() => {
				setApplicable(() => {
					return true;
				});
			})
			.catch(async (error: any) => {
				const errorMessage = await getErrorFromResponse(error);
				setApplicable(false);
				setErrorCode(errorMessage?.code || null);
			});

		return applicable;
	};

	const applyToPoliJobsProposal = () => {
		if (!poliJobsProposal) return;

		setSpinning(true);

		applyPoliJobsProposal(poliJobsProposal?.id!)
			.then(async () => {
				setApplicable(false);
				await queryClient.invalidateQueries(getSpecificPoliJobsProposal);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("polijobs.applyToPoliJobsOfferSuccess"),
					t("polijobs.applyToPoliJobsOfferSuccessText"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((error: any) => {
				console.error(error);

				if (error) {
					if (error.status === StatusCodes.CONFLICT) {
						openNotification(
							t("polijobs.applyToPoliJobsOfferError"),
							t("polijobs.applyToPoliJobsOfferConflict"),
							NOTIFICATION_TYPES.ERROR
						);
					} else {
						openNotification(
							t("polijobs.applyToPoliJobsOfferError"),
							t("polijobs.applyToPoliJobsOfferOtherError"),
							NOTIFICATION_TYPES.ERROR
						);
					}
				}
			})
			.finally(() => {
				setSpinning(false);
				setModalVisibility(false);
			});
	};

	useEffect(() => {
		if (isUserLogged() && isStudent) {
			setApplicable(canApplyToPoliJobsProposal());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [poliJobsProposal?.id, isStudent]);

	const applicationMessage = useErrorCodeTranslation(errorCode);

	const returnAction = t("polijobs.uploadedFile");

	const handleDelete = () => {
		deletePoliJobsProposal(poliJobsProposal?.id!)
			.then(async () => {
				await invalidatePoliJobsProposalsQueries();
				openNotification(
					t("polijobs.deleteOffer"),
					t("polijobs.deletePoliJobsOfferSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error: any) => {
				openNotification(
					t("polijobs.deleteOffer"),
					t("polijobs.deletePoliJobsOfferError"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setDeleteModalVisibility(false);

				history.push(location.state!.origin);
			});
	};

	const invalidatePoliJobsProposalsQueries = async () => {
		await queryClient.invalidateQueries(getSpecificPoliJobsProposal);
	};

	const downloadFailJobDescription = (_err: any) => {
		openNotification(
			t("polijobs.downloadJobDescription"),
			t("polijobs.downloadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const downloadJobDescription = (poliJobsProposalId: any) => {
		downloadFile(
			downloadPoliJobsProposalJD(poliJobsProposalId),
			t("polijobs.jobDescriptionFileName") +
				"_" +
				poliJobsProposal?.recruiterName +
				"_" +
				poliJobsProposal?.poliJobsProposalName
		).catch(downloadFailJobDescription);
	};

	const uploadJobDescription = (id: string, file: Blob) => {
		return uploadPoliJobsProposalJD(id, file).then(async () => {
			await queryClient.invalidateQueries("getInternship");
		});
	};

	const uploadSucceeded = () => {
		openNotification(
			t("polijobs.uploadedFile"),
			t("polijobs.uploadSuccess"),
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const uploadFailed = (_err: any) => {
		openNotification(
			t("polijobs.error"),
			t("polijobs.uploadError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const changeTab = (activeKey: any) => {
		setActiveTab(activeKey);
	};

	return (
		<Spin size="large" spinning={!poliJobsProposal} tip={t("polijobs.loading")}>
			<div className="flex flex-col container mx-auto px-4 py-10 gap-8">
				<div className="flex items-center justify-between gap-6">
					<div className="flex items-center gap-6">
						{location.state && (
							<Link
								to={{
									pathname: location.state.origin,
								}}
							>
								<ArrowDownOutlined className="rotate-90 text-4xl text-primary" />
							</Link>
						)}

						<div className="flex flex-col items-center">
							<Avatar src={poliJobsProposal?.recruiterAvatar} />

							{poliJobsProposal?.recruiterName}
						</div>

						<div className={styles.title}>
							{poliJobsProposal?.poliJobsProposalName}
						</div>
					</div>

					<div className="flex flex-col gap-10 bg-white rounded-md shadow-md p-6 w-full xl:max-w-lg">
						{location.state && (
							<div className="flex gap-1 flex-wrap">
								{isPoliJobsAdmin &&
								poliJobsProposal ? (
									<Button type="primary" title={t("polijobs.editOffer")}>
										<Link
											to={{
												pathname: `${Routes.EDIT_POLIJOBS}/${poliJobsProposal?.id}`,
											}}
										>
											<FontAwesomeIcon icon={solid("edit")} />
											&nbsp;{t("polijobs.editOffer")}
										</Link>
									</Button>
								) : null}

								{isPoliJobsAdmin &&
									poliJobsProposal &&
									poliJobsProposal.jobDescriptionFileName && (
										<PoliJobsRecommendationsGenerationModal
											poliJobsProposalId={poliJobsProposal?.id}
											invalidationHandler={invalidateRecommendationsQuery}
										/>
									)}

								{(isPoliJobsAdmin || isStudent) &&
								poliJobsProposal &&
								poliJobsProposal.jobDescriptionFileName ? (
									<Button
										type="primary"
										onClick={() => downloadJobDescription(poliJobsProposal?.id)}
										title={t("polijobs.downloadJobDescription")}
									>
										<Space>
											{
												<FontAwesomeIcon
													icon={solid("download")}
													title={t("polijobs.downloadJobDescription")}
												/>
											}
											{t("polijobs.downloadJobDescription")}
										</Space>
									</Button>
								) : null}

								{isPoliJobsAdmin &&
								poliJobsProposal ? (
									<Upload
										accept=".pdf"
										action={(uploaded) => {
											uploadJobDescription(poliJobsProposal.id!, uploaded)
												.then(uploadSucceeded)
												.then(invalidatePoliJobsProposalsQueries)
												.catch(uploadFailed);
											return returnAction;
										}}
										showUploadList={false}
										multiple={false}
										customRequest={({ onSuccess }) => {
											onSuccess && onSuccess("");
										}}
									>
										<Button
											type="primary"
											title={t("polijobs.uploadJobDescription")}
										>
											<Space>
												{
													<FontAwesomeIcon
														icon={solid("upload")}
														title={t("polijobs.uploadJobDescription")}
													/>
												}
												{t("polijobs.uploadJobDescription")}
											</Space>
										</Button>
									</Upload>
								) : null}

								{isPoliJobsAdmin &&
								poliJobsProposal ? (
									<Button
										danger
										onClick={() => setDeleteModalVisibility(true)}
										title={
											poliJobsProposal &&
											poliJobsProposal.enrolledStudentsCount! > 0
												? t("polijobs.deletionNotAllowed")
												: t("polijobs.deleteOffer")
										}
										icon={
											<FontAwesomeIcon
												icon={solid("trash")}
												style={{ paddingRight: "5%" }}
											/>
										}
										disabled={
											poliJobsProposal &&
											poliJobsProposal.enrolledStudentsCount! > 0
										}
									>
										{t("polijobs.deleteOffer")}
									</Button>
								) : null}

								<ConfirmationModal
									modalText={t("polijobs.deleteMessage")}
									handleFunction={() => {
										handleDelete();
									}}
									modalVisibility={deleteModalVisibility}
									title=""
									changeModalVisibility={() => setDeleteModalVisibility(false)}
									spinning={spinning}
								/>
							</div>
						)}

						<div className="flex flex-wrap items-center gap-2 w-full">
							<PoliJobsProposalActions item={poliJobsProposal!} />
						</div>
					</div>
				</div>

				{isStudent && (
					<>
						<div className="text-xl text-left font-bold mt-5 mb-2.5">
							{t("polijobs.jobDescription")}
						</div>
						<div
							className={styles.description}
							dangerouslySetInnerHTML={{
								__html: poliJobsProposal?.description || "",
							}}
						/>
					</>
				)}

				<div className="flex flex-col-reverse xl:flex-row gap-10 w-full lg:items-start">
					<div className="flex flex-col gap-6 basis-full">
						{!isUserLogged() ? (
							<div className={styles.center}>
								<Link to="/autentificare">
									<CustomButton
										backgroundcolor={theme.primaryColor}
										textcolor={theme.white}
										fontSize={"1rem"}
										width={"10rem"}
										paddingvertical={"1.2rem"}
										paddinghorizontal={"1.4rem"}
										marginleft={"3rem"}
									>
										{t("polijobs.loginToApply")}
									</CustomButton>
								</Link>
							</div>
						) : isStudent && !applicable ? (
							<div className={styles.message}>{applicationMessage}</div>
						) : (
							isStudent &&
							applicable && (
								<div className={styles.center}>
									<div>
										<Row
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "baseline",
											}}
										>
											<CustomButton
												fontSize={"0.9rem"}
												style={{
													background: theme.secondColor,
													color: theme.white,
													marginRight: "2em",
													marginTop: "5%",
													boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
												}}
												onClick={() => setModalVisibility(true)}
											>
												{t("polijobs.apply")}
											</CustomButton>
											{poliJobsProposal?.url && (
												<a
													href={poliJobsProposal.url}
													target="_blank"
													rel="noopener noreferrer"
												>
													<CustomButton
														fontSize={"0.9rem"}
														style={{
															background: theme.secondColor,
															color: theme.white,
															marginRight: "2em",
															marginTop: "5%",
															boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
														}}
													>
														{t("polijobs.poliJobsOfferLink")}
													</CustomButton>
												</a>
											)}
										</Row>
										<ConfirmationModal
											modalText={t("polijobs.applyPoliJobsOfferMessage")}
											handleFunction={applyToPoliJobsProposal}
											modalVisibility={modalVisibility}
											title=""
											changeModalVisibility={() => setModalVisibility(false)}
											spinning={spinning}
										/>
									</div>
								</div>
							)
						)}
						{isPoliJobsAdmin && (
							<Tabs
								tabBarStyle={{
									padding: "0px 1rem",
								}}
								activeKey={activeTab}
								onChange={changeTab}
							>
								<TabPane tab={t("polijobs.recommendations")} key="1">
									<PoliJobsRecommendationsTable poliJobsProposalId={id} />
								</TabPane>
								<TabPane tab={t("polijobs.applicants")} key="2">
									<PoliJobsApplicantsTable
										poliJobsProposalId={id}
										type={InternType.Applicant}
									/>
								</TabPane>
								<TabPane tab={t("polijobs.trainees")} key="3">
									<PoliJobsApplicantsTable
										poliJobsProposalId={id}
										type={InternType.Trainee}
									/>
								</TabPane>
								<TabPane tab={t("polijobs.jobDescription")} key="4">
									<div
										className={styles.description}
										dangerouslySetInnerHTML={{
											__html: poliJobsProposal?.description || "",
										}}
									/>
								</TabPane>
							</Tabs>
						)}
					</div>
				</div>
			</div>
		</Spin>
	);
};

export default PoliJobsProposal;
