import { Row, Radio, Space, RadioChangeEvent, Checkbox, Input } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import styles from "./Feedback.module.scss";
import { QuestionTypeEnum } from "../../Api/models/QuestionTypeEnum";

const likertSatisfcationAnswers = [
	"1 (Nesatisfăcător)",
	"2 (Satisfăcător)",
	"3 (Bine)",
	"4 (Foarte bine)",
	"5 (Excelent)",
	"Nu se aplica",
];
const likertAccordAnswers = [
	"1 (Total dezacord)",
	"2 (Dezacord)",
	"3 (Nici acord, nici dezacord)",
	"4 (Acord)",
	"5 (Total acord)",
	"Nu se aplica",
];

const likertOportunAnswers = [
	"1 – Total inoportun",
	"2 – Inoportun",
	"3 – Nici oportun nici inoportun",
	"4 –Oportun",
	"5 – Foarte oportun",
	"Nu știu /Nu răspund",
];

const likertSatisfcationAnswers2 = [
	"1 – Total nesatisfăcătoare",
	"2 – Nesatisfăcătoare",
	"3 – Acceptabilă",
	"4 –Satisfăcătoare",
	"5 – Total Satisfăcătoare",
	"Nu știu /Nu răspund",
];

const likertSuficientAnswers = [
	"1 – Insuficient",
	"2 – Corespunzător",
	"3 – Prea mare",
	"Nu știu /Nu răspund",
];

const likertTimeAnswers = [
	"1 – Niciodată",
	"2 – Rareori",
	"3 – Uneori",
	"4 – Frecvent",
	"5 – Întotdeauna",
	"Nu știu /Nu răspund",
];

const YesNoAnswers = ["Da", "Nu", "Nu se aplica"];

type CustomFormItemProps = {
	value?: number | number[] | string;
	onChange?: (value: number | number[] | string) => void;
	props: {
		question: string;
		type?: QuestionTypeEnum;
		answers?: string[];
		answerType?: string;
	};
};

const Question: React.FC<CustomFormItemProps> = ({
	value,
	onChange,
	props,
}) => {
	const onChangeSingleChoice = (e: RadioChangeEvent) => {
		if (typeof onChange === "function") {
			onChange(e.target.value);
		}
	};

	const onChangeMultipleChoice = (checkedValues: CheckboxValueType[]) => {
		if (typeof onChange === "function") {
			onChange(checkedValues as number[]);
		}
	};

	const onChangeText = (e: any) => {
		if (typeof onChange === "function") {
			onChange(e.target.value);
		}
	};

	return (
		<div className="border border-solid rounded-lg border-primary px-4 pb-4 bg-white">
			<p className="">
				<span style={{ color: "#ff4d4f" }}>*&nbsp;</span>
				{props.question}
			</p>

			{/* Single choice question */}
			{props.type === QuestionTypeEnum.SingleChoice && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					className="w-full space-y-2"
				>
					{props.answers?.map((answer) => {
						return (
							<Radio value={answer} key={answer} className="w-full">
								{answer}
							</Radio>
						);
					})}
				</Radio.Group>
			)}

			{/* Multiple choice question */}
			{props.type === QuestionTypeEnum.MultipleChoice && (
				<Checkbox.Group
					defaultValue={value as number[]}
					onChange={onChangeMultipleChoice}
					className="w-full space-y-2"
				>
					{props.answers?.map((answer) => {
						return (
							<Checkbox value={answer} key={answer} className="w-full">
								{answer}
							</Checkbox>
						);
					})}
				</Checkbox.Group>
			)}

			{/* LikertAccord question */}
			{props.type === QuestionTypeEnum.LikertAccord && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertAccordAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{/* LikertSatisfaction question */}
			{props.type === QuestionTypeEnum.LikertSatisfaction && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertSatisfcationAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{props.answerType == "oportun" && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertOportunAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{props.answerType == "suficient" && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertSuficientAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{props.answerType == "timeSpan" && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{likertTimeAnswers.map((answer, index) => {
						return (
							<Radio.Button value={index + 1} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{/* YesNo question */}
			{props.type === QuestionTypeEnum.YesNo && (
				<Radio.Group
					value={value}
					onChange={onChangeSingleChoice}
					buttonStyle="solid"
				>
					{YesNoAnswers.map((answer) => {
						return (
							<Radio.Button value={answer} key={answer}>
								{answer}
							</Radio.Button>
						);
					})}
				</Radio.Group>
			)}

			{/* OpenText question */}
			{props.type === QuestionTypeEnum.OpenText && (
				<Input.TextArea
					defaultValue={value as string}
					autoSize={{ minRows: 2, maxRows: 8 }}
					onChange={onChangeText}
				/>
			)}
		</div>
	);
};

export default Question;
