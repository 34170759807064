import { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Select, Spin, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import {
	openNotification,
	NOTIFICATION_TYPES,
} from "../Notifications/NotificationsUtils";
import moment from "moment";
import {
	addColaborationHistory,
	updateColaborationHistory,
} from "../../Requests/colaboration-history-requests";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { ErrorCodes } from "../../Api";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AddColaborationHistoryModal = (props: {
	companyId: string;
	editMode?: boolean;
	colaborationHistory?: any;
	onClose: () => void;
	open: boolean;
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const queryClient = useQueryClient();
	const [spinning, setSpinning] = useState(false);

	useEffect(() => {
		if (props.editMode && props.colaborationHistory) {
			form.setFieldsValue({
				colaborationType: props.colaborationHistory.colaborationType,
				description: props.colaborationHistory.description,
				dateRange: [
					moment(props.colaborationHistory.from),
					moment(props.colaborationHistory.to),
				]
			});
		} else {
			form.resetFields();
		}
	}, [props.editMode, props.colaborationHistory, form]);

	const handleSave = async (values: any) => {
		setSpinning(true);
		const [from, to] = values.dateRange;

		const colaborationHistoryDTO = {
			colaborationType: values.colaborationType,
			description: values.description,
			from: from.toDate(),
			to: to.toDate(),
			companyId: props.companyId,
		};

		try {
			if (props.editMode && props.colaborationHistory) {

				await updateColaborationHistory(
					props.colaborationHistory.id,
					colaborationHistoryDTO
				);
				openNotification(
					t("account.colaborationHistoryEdit"),
					t("account.colaborationHistoryEditSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			} else {
				await addColaborationHistory(colaborationHistoryDTO);
				openNotification(
					t("account.colaborationHistoryAdd"),
					t("account.colaborationHistoryAddSuccess"),
					NOTIFICATION_TYPES.SUCCESS
				);
			}
			await queryClient.invalidateQueries([
				"colaborationHistories",
				props.companyId,
			]);
			props.onClose();
		} catch (ex) {
			const error = await getErrorFromResponse(ex as Response);
			openNotification(
				t("account.colaborationHistoryAdd"),
				error?.code === ErrorCodes.Forbidden
					? t("account.noPermissions")
					: error?.code === ErrorCodes.ColaborationHistoryNotFound
					? t("account.colaborationHistoryNotFound")
					: t("account.colaborationHistoryAddError"),
				NOTIFICATION_TYPES.ERROR
			);
		} finally {
			setSpinning(false);
		}
	};

	return (
		<Modal
			open={props.editMode ? props.editMode : props.open}
			onOk={() => form.submit()}
			onCancel={props.onClose}
			title={
				props.editMode
					? t("account.colaborationHistoryEdit")
					: t("account.colaborationHistoryAdd")
			}
			width={window.innerWidth > 1215 ? "30%" : "80%"}
			footer={
				<Button type="primary" onClick={() => form.submit()} loading={spinning}>
					{t("account.confirm")}
				</Button>
			}
		>
			<Spin spinning={spinning}>
				<Form form={form} layout="vertical" onFinish={handleSave}>
					<Form.Item
						name="colaborationType"
						label={t("account.colaborationType")}
						style={{ width: "100%" }}
						rules={[
							{
								required: true,
								message: t("account.colaborationTypeRequired"),
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="description"
						label={t("account.description")}
						style={{ width: "100%" }}
						rules={[
							{ required: true, message: t("account.descriptionRequired") },
						]}
					>
						<Input.TextArea />
					</Form.Item>
					<Form.Item
						name="dateRange"
						label={t("account.interval")}
						style={{ width: "100%" }}
						rules={[
							{ required: true, message: t("account.dateRangeRequired") },
						]}
					>
						<RangePicker style={{ width: "100%" }} />
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	);
};

export default AddColaborationHistoryModal;
