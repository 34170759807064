import { NotificationDTO, NotificationType } from "../../Api";
import { theme } from "../../theme";

export const buildNotificationText = (
	notification: NotificationDTO,
	isStudent: any,
	t: any,
	lng: any
) => {
	// when you add new notification type add also in buildNotificationColor and buildNotificationTextColor functions!!!
	switch (notification.type) {
		case NotificationType.CompanyValidationNeeded:
			return (
				`${t("notifications.company")} ${notification.issuerName} ` +
				`${t("notifications.joined")} ${t("notifications.approvalNeeded")}.`
			);
		case NotificationType.AccordUploaded:
			return (
				`${t("notifications.company")} ${notification.issuerName} ` +
				`${t("notifications.generalAccordUploaded")}.`
			);
		case NotificationType.AccordValidationNeeded:
			return (
				`${t("notifications.generalAccord")} ${t(
					"notifications.forCompany"
				)} ` +
				`${notification.issuerName} ${t("notifications.validationNeeded")}.`
			);
		case NotificationType.AccordSignatureNeeded:
			return (
				`${t("notifications.generalAccord")} ${t(
					"notifications.forCompany"
				)} ` +
				`${notification.issuerName} ${t("notifications.signatureNeeded")}.`
			);
		case NotificationType.AccordFinalized:
			return t("notifications.accordFinalized");
		case NotificationType.RepresentativeNominated:
			return (
				`${notification.issuerName} ${t(
					"notifications.representativeNominated"
				)} ` + `${notification.issuerAction}.`
			);
		case NotificationType.TutorNominated:
			return `${notification.issuerName} ${t("notifications.tutorNominated")} ${
				notification.issuerAction
			}.`;
		case NotificationType.FacultyCoordinatorNominated:
			return `${t("notifications.company")} ${notification.issuerName} ${t(
				"notifications.facultyCoordinatorNominated"
			)} ${notification.issuerAction}.`;
		case NotificationType.FacultyCoordinatorDesignated:
			return `${t("notifications.facultyAdmin")} ${t(
				"notifications.facultyCoordinatorNominated"
			)} ${notification.issuerAction}.`;
		case NotificationType.FacultyCoordinatorAccepted:
			return `${notification.issuerName} ${t(
				"notifications.acceptedCoordination"
			)} ${notification.issuerAction}.`;
		case NotificationType.FacultyCoordinatorRejected:
			return `${notification.issuerName} ${t(
				"notifications.rejectedCoordination"
			)} ${notification.issuerAction}.`;
		case NotificationType.ProfessorInternshipValidated:
		case NotificationType.CompanyInternshipValidated:
			return `${t("notifications.internship2")} ${notification.issuerName} ${t(
				"notifications.validated"
			)} ${notification.issuerAction}.`;
		case NotificationType.ProfessorInternshipInvalidated:
		case NotificationType.CompanyInternshipInvalidated:
			return `${t("notifications.internship2")} ${notification.issuerName} ${t(
				"notifications.invalidated"
			)} ${notification.issuerAction}.`;
		case NotificationType.SkillEndorsed:
			return `${notification.issuerName} ${t("notifications.skillEndorsed")} ${
				notification.issuerAction
			}.`;
		case NotificationType.AppreciationReceived:
			return `${notification.issuerName} ${t(
				"notifications.appreciationReceived"
			)}.`;
		case NotificationType.InternshipValidationNeeded:
			return (
				`${t("notifications.internship2")} ${notification.issuerName} ` +
				`${t("notifications.publishedBy")} ${notification.issuerAction} ${t(
					"notifications.validationNeeded"
				)}.`
			);
		case NotificationType.OfferPublished:
			return (
				`${t("notifications.trustedCompany")} ${notification.issuerName} ` +
				`${t("notifications.publishedInternship")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.OfferUpdated:
			return (
				`${t("notifications.trustedCompany")} ${notification.issuerName} ` +
				`${t("notifications.updatedInternship")} ${notification.issuerAction}.`
			);
		case NotificationType.InternshipApproved:
			return `${t("notifications.internship2")} ${notification.issuerName} ${t(
				"notifications.approved2"
			)}.`;
		case NotificationType.InternshipRejected:
			return (
				`${t("notifications.internship2")} ${notification.issuerName} ${t(
					"notifications.rejected3"
				)}. ` + `${t("notifications.checkMail")}.`
			);
		case NotificationType.ProfessorJoinedInternship:
			return (
				`${notification.issuerName} ${t("notifications.joinedInternship")} ${
					notification.issuerAction
				} ` + `${t("notifications.asCoordinator")}.`
			);
		case NotificationType.InternshipChanged:
			return (
				`${t("notifications.internship2")} ${notification.issuerName} ` +
				`${t("notifications.internshipChanged")}`
			);
		case NotificationType.InternshipDeleted:
			return (
				`${t("notifications.internship2")} ${notification.issuerName} ` +
				`${t("notifications.internshipDeleted")}`
			);
		case NotificationType.InternshipStudentApplication:
			return (
				`${t("notifications.student")} ${notification.issuerName} ` +
				`${t("notifications.applied")} ${t("notifications.internship")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipCompanyAcceptance:
			return (
				`${t("notifications.company")} ${notification.issuerName} ` +
				`${t("notifications.accepted")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipProfessorAcceptance:
			return (
				`${t("notifications.professor")} ${notification.issuerName} ` +
				`${t("notifications.accepted")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipCompanyRejection:
			return (
				`${t("notifications.company")} ${notification.issuerName} ` +
				`${t("notifications.rejected")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipProfessorRejection:
			return (
				`${t("notifications.professor")} ${notification.issuerName} ` +
				`${t("notifications.rejected")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.PoliJobsProposalAccepted:
			return (
				`${t("notifications.poliJobsStaff")} ` +
				`${t("notifications.poliJobsAcceptedApplication")} ${
					notification.issuerAction
				}, ${t("notifications.poliJobsAvailableAt")} ${
					notification.issuerName
				}. ${t("notifications.poliJobsApplicationForwarded")}`
			);
		case NotificationType.PoliJobsProposalRejected:
			return (
				`${t("notifications.poliJobsStaff")} ` +
				`${t("notifications.poliJobsRejectedApplication")} ${
					notification.issuerAction
				} ${t("notifications.poliJobsAvailableAt")} ${notification.issuerName}.`
			);
		case NotificationType.PoliJobsProposalCvRejected:
			return (
				`${t("notifications.poliJobsStaff")} ` +
				`${t("notifications.poliJobsRejectedCV")} ${
					notification.issuerAction
				} ${t("notifications.poliJobsAvailableAt")} ${
					notification.issuerName
				}. ${t("notifications.poliJobsReviewCV")}`
			);
		case NotificationType.InternshipStudentAcceptance:
			return (
				`${t("notifications.student")} ${notification.issuerName} ` +
				`${t("notifications.accepted")} ${t("notifications.internship")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipStudentRejection:
			return (
				`${t("notifications.student")} ${notification.issuerName} ` +
				`${t("notifications.rejected")} ${t("notifications.internship")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipStudentRejectionAfterAccept:
			return (
				`${t("notifications.student")} ${notification.issuerName} ` +
				`${t("notifications.withdraw")} ${t("notifications.internship")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.InternshipEnrollmentDeleted:
			return `${t("notifications.yourEnrollment")} ${
				notification.issuerName
			} ${t("notifications.enrollmentDeleted")}`;
		case NotificationType.ConventionAcknowledgementNeeded:
			return (
				`${t("notifications.practiceConvention")} ${t(
					"notifications.forStudentEnrollment"
				)} ` +
				`${notification.issuerName} ${t("notifications.inInternship")} ${
					notification.issuerAction
				} ` +
				`${t("notifications.agreementNeeded")}.`
			);
		case NotificationType.ConventionSignatureNeeded:
			return isStudent
				? `${t("notifications.practiceConvention")} ${t(
						"notifications.forYourEnrollment"
				  )} ` +
						`${t("notifications.inInternship")} ${
							notification.issuerAction
						} ${t("notifications.signatureNeeded")}.`
				: `${t("notifications.practiceConvention")} ${t(
						"notifications.forStudentEnrollment"
				  )} ` +
						`${notification.issuerName} ${t("notifications.inInternship")} ${
							notification.issuerAction
						} ` +
						`${t("notifications.signatureNeeded")}.`;
		case NotificationType.NotebookSignatureNeeded:
			return (
				`${t("notifications.practiceNotebook")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.signatureNeeded")}.`
			);
		case NotificationType.NotebookValidationNeeded:
			return (
				`${t("notifications.practiceNotebook")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.validationNeeded")}.`
			);
		case NotificationType.NotebookUpdated:
			return (
				`${t("notifications.practiceNotebook")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.documentUpdated")}.`
			);
		case NotificationType.NotebookApproved:
			return (
				`${t("notifications.practiceNotebook")} ${t(
					"notifications.forYourEnrollment"
				)} ` +
				`${t("notifications.inInternship")} ${notification.issuerAction} ${t(
					"notifications.approved"
				)}. ` +
				`${t("notifications.canDownload")}.`
			);
		case NotificationType.NotebookRejected:
			return (
				`${t("notifications.practiceNotebook")} ${t(
					"notifications.forYourEnrollment"
				)} ` +
				`${t("notifications.inInternship")} ${notification.issuerAction} ${t(
					"notifications.rejected2"
				)}. ` +
				`${t("notifications.review2")}.`
			);
		case NotificationType.FinalNotebookRejected:
			return (
				`${t("notifications.practiceNotebook")} ${t(
					"notifications.forYourEnrollment"
				)} ` +
				`${t("notifications.inInternship")} ${notification.issuerName} ${t(
					"notifications.rejected2"
				)}. ` +
				`${t("notifications.checkEmail")}.`
			);
		case NotificationType.CertificateSignatureNeeded:
			return (
				`${t("notifications.practiceCertificate")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.signatureNeeded")}.`
			);
		case NotificationType.CertificateValidationNeeded:
			return (
				`${t("notifications.practiceCertificate")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.validationNeeded")}.`
			);
		case NotificationType.CertificateUpdated:
			return (
				`${t("notifications.practiceCertificate")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.documentUpdated")}.`
			);
		case NotificationType.CertificateApproved:
			return (
				`${t("notifications.practiceCertificate")} ${t(
					"notifications.forYourEnrollment"
				)} ` +
				`${t("notifications.inInternship")} ${notification.issuerAction} ${t(
					"notifications.approved"
				)}. ` +
				`${t("notifications.canDownload")}.`
			);
		case NotificationType.CertificateRejected:
			return (
				`${t("notifications.practiceCertificate")} ${t(
					"notifications.forInternshipCompletion"
				)} ` +
				`${notification.issuerAction} ${t("notifications.byStudent")} ${
					notification.issuerName
				} ` +
				`${t("notifications.rejected2")}. ${t("notifications.review")}.`
			);
		case NotificationType.FinalCertificateRejected:
			return (
				`${t("notifications.practiceCertificate")} ${t(
					"notifications.forYourEnrollment"
				)} ` +
				`${t("notifications.inInternship")} ${notification.issuerName} ${t(
					"notifications.rejected2"
				)}. ` +
				`${t("notifications.checkEmail")}.`
			);
		case NotificationType.AccordApproved:
			return `${t("notifications.universityAccord")} ${t(
				"notifications.accordApproved"
			)}.`;
		case NotificationType.AccordRejected:
			return `${t("notifications.universityAccord")} ${t(
				"notifications.accordRejected"
			)}. ${t("notifications.checkMail")}.`;
		case NotificationType.SupervisorAssigned:
			return (
				`${notification.issuerName} ${t(
					"notifications.joinedYourInternship"
				)} ${notification.issuerAction} ` +
				`${t("notifications.asSupervisor")}.`
			);
		case NotificationType.SupervisorUnassigned:
			return `${notification.issuerName} ${t(
				"notifications.supervisorUnassigned"
			)} ${notification.issuerAction}.`;
		case NotificationType.ConventionValidated:
			return `${notification.issuerName} ${t(
				"notifications.conventionValidated"
			)} ${notification.issuerAction}.`;
		case NotificationType.InternshipDocumentSignatureNeeded:
			return (
				`${t("notifications.internshipDocument")} ${t(
					"notifications.forStudent"
				)} ${notification.issuerName} ` +
				`${t("notifications.validationNeeded")}.`
			);
		case NotificationType.InternshipEnrollment:
			return `${t("notifications.secretaryEnrolled")} ${
				notification.issuerName
			} ${t("notifications.publishedBy")} ${notification.issuerAction}.`;
		case NotificationType.PracticeProtocolAdded:
			return `${t("notifications.company")} ${notification.issuerName} ${t(
				"notifications.uploadPracticeProtocol"
			)}.`;
		case NotificationType.PracticeProtocolChanged:
			return `${t("notifications.company")} ${notification.issuerName} ${t(
				"notifications.uploadedPracticeProtocolNewVersion"
			)}.`;
		case NotificationType.PracticeProtocolRejected:
			return `${t("notifications.rejectedPracticeProtocol")} ${
				lng === "ro" ? notification.issuerName : notification.issuerAction
			}. ${t("notifications.checkMail")}.`;
		case NotificationType.PracticeProtocolFinalized:
			return `${t("notifications.uploadedPracticeProtocolFinalized")} ${
				lng === "ro" ? notification.issuerName : notification.issuerAction
			}.`;
		case NotificationType.CompanyPracticeSupervisorDesignated:
			return `${t("notifications.supervisorDesignated")} ${
				notification.issuerName
			}.`;
		case NotificationType.CompanyPracticeSupervisorRemoved:
			return `${t("notifications.supervisorRemoved")} ${
				notification.issuerName
			}.`;
		case NotificationType.SupervisedStudyProgramAdded:
			return `${t("notifications.supervisedStudyProgramAdded")} ${
				notification.issuerName
			}.`;
		case NotificationType.SupervisedStudyProgramRemoved:
			return `${t("notifications.supervisedStudyProgramRemoved")} ${
				notification.issuerName
			}.`;
		case NotificationType.CompanyAdminNominated:
			return (
				`${notification.issuerName} ${t(
					"notifications.companyAdminNominated"
				)} ` + `${notification.issuerAction}.`
			);
		case NotificationType.CompanyAdminRevoked:
			return (
				`${notification.issuerName} ${t(
					"notifications.companyAdminRevoked"
				)} ` + `${notification.issuerAction}.`
			);
		case NotificationType.UpcomingExpiringOffer:
			return `${t("notifications.yourOffer")} ${notification.issuerName} ${t(
				"notifications.publishedBy"
			)} ${notification.issuerAction} ${t("notifications.willExpire")}`;
		case NotificationType.ExpiredOffer:
			return `${t("notifications.yourOffer")} ${notification.issuerAction} ${t(
				"notifications.forStudent"
			)} ${notification.issuerName} ${t("notifications.expired")}`;
		case NotificationType.PracticeOverdueMarked:
			return `${t("notifications.practiceOverdueMarked")}`;
		case NotificationType.PracticeOverdueRemoved:
			return `${t("notifications.practiceOverdueRemoved")}`;
		case NotificationType.EngagementTutorNominated:
			return `${t("notifications.engagementTutorNominated")} ${
				notification.issuerName
			} ${t("notifications.toOffer")} ${notification.issuerAction}.`;

		case NotificationType.ResearchProposalByCompanyAccepted:
			return (
				`${t("notifications.company")} ${notification.issuerName} ` +
				`${t("notifications.rejected")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.ResearchProposalByProfessorAccepted:
			return (
				`${t("notifications.professor")} ${notification.issuerName} ` +
				`${t("notifications.accepted")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.ResearchProposalByCompanyRejected:
			return (
				`${t("notifications.company")} ${notification.issuerName} ` +
				`${t("notifications.rejected")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.ResearchProposalByProfessorRejected:
			return (
				`${t("notifications.professor")} ${notification.issuerName} ` +
				`${t("notifications.rejected")} ${t("notifications.application")} ${
					notification.issuerAction
				}.`
			);
		case NotificationType.PossibleInterestInResearchProposal:
			return `${t(`notifications.researchProposal`)} "${
				notification.issuerName
			}" ${t("notifications.termsOfInterest")}`;
		case NotificationType.PoliJobsProposalNewApplicant:
			return (
				`${t("notifications.student")} ${notification.issuerName} ` +
				`${t("notifications.poliJobsApplied")} ${notification.issuerAction}.`
			);
		case NotificationType.PossibleInterestInPoliJobsProposal:
			return `${t("notifications.poliJobsStaff")} ${t(
				"notifications.poliJobsRecommendation"
			)} "${notification.issuerName}" ${t(
				"notifications.poliJobsAvailableAt"
			)} ${notification.issuerAction}.`;
	}

	return "";
};

export const buildNotificationColor = (notification: NotificationDTO) => {
	switch (notification.type) {
		case NotificationType.CompanyValidationNeeded:
		case NotificationType.RepresentativeNominated:
		case NotificationType.TutorNominated:
		case NotificationType.FacultyCoordinatorNominated:
		case NotificationType.ConventionAcknowledgementNeeded:
		case NotificationType.NotebookValidationNeeded:
		case NotificationType.InternshipDocumentSignatureNeeded:
		case NotificationType.NotebookApproved:
		case NotificationType.CertificateValidationNeeded:
		case NotificationType.CertificateUpdated:
		case NotificationType.CertificateApproved:
		case NotificationType.InternshipEnrollment:
		case NotificationType.PracticeProtocolAdded:
		case NotificationType.CompanyPracticeSupervisorDesignated:
		case NotificationType.CompanyPracticeSupervisorRemoved:
		case NotificationType.SupervisedStudyProgramAdded:
		case NotificationType.SupervisedStudyProgramRemoved:
		case NotificationType.OfferPublished:
		case NotificationType.OfferUpdated:
		case NotificationType.CompanyAdminNominated:
		case NotificationType.EngagementTutorNominated:
			return theme.yellow;
		case NotificationType.AccordUploaded:
		case NotificationType.AccordValidationNeeded:
		case NotificationType.AccordSignatureNeeded:
		case NotificationType.InternshipChanged:
		case NotificationType.ConventionSignatureNeeded:
		case NotificationType.NotebookSignatureNeeded:
		case NotificationType.NotebookUpdated:
		case NotificationType.CertificateSignatureNeeded:
		case NotificationType.FacultyCoordinatorDesignated:
		case NotificationType.PracticeProtocolChanged:
			return theme.orange;
		case NotificationType.InternshipValidationNeeded:
		case NotificationType.InternshipDeleted:
		case NotificationType.InternshipEnrollmentDeleted:
		case NotificationType.NotebookRejected:
		case NotificationType.FinalNotebookRejected:
		case NotificationType.CertificateRejected:
		case NotificationType.FinalCertificateRejected:
		case NotificationType.InternshipRejected:
		case NotificationType.AccordRejected:
		case NotificationType.FacultyCoordinatorRejected:
		case NotificationType.ProfessorInternshipInvalidated:
		case NotificationType.CompanyInternshipInvalidated:
		case NotificationType.SupervisorUnassigned:
		case NotificationType.CompanyAdminRevoked:
		case NotificationType.PracticeOverdueMarked:
		case NotificationType.PracticeProtocolRejected:
			return theme.lightRed;
		case NotificationType.SkillEndorsed:
		case NotificationType.AppreciationReceived:
		case NotificationType.InternshipApproved:
		case NotificationType.ProfessorJoinedInternship:
		case NotificationType.SupervisorAssigned:
		case NotificationType.ConventionValidated:
		case NotificationType.AccordFinalized:
		case NotificationType.ProfessorInternshipValidated:
		case NotificationType.CompanyInternshipValidated:
		case NotificationType.PracticeOverdueRemoved:
			return theme.thirdGreen;
		case NotificationType.InternshipStudentApplication:
		case NotificationType.InternshipCompanyAcceptance:
		case NotificationType.InternshipProfessorAcceptance:
		case NotificationType.PoliJobsProposalAccepted:
		case NotificationType.InternshipStudentAcceptance:
		case NotificationType.AccordApproved:
		case NotificationType.FacultyCoordinatorAccepted:
		case NotificationType.PracticeProtocolFinalized:
		case NotificationType.PossibleInterestInResearchProposal:
		case NotificationType.PossibleInterestInPoliJobsProposal:
		case NotificationType.PoliJobsProposalNewApplicant:
			return theme.lightGreen;
		case NotificationType.InternshipCompanyRejection:
		case NotificationType.InternshipStudentRejection:
			return theme.purple;
		case NotificationType.InternshipProfessorRejection:
		case NotificationType.PoliJobsProposalRejected:
		case NotificationType.PoliJobsProposalCvRejected:
			return theme.pink;

		case NotificationType.ResearchProposalByCompanyAccepted:
		case NotificationType.ResearchProposalByProfessorAccepted:
			return theme.lightGreen;
		case NotificationType.ResearchProposalByCompanyRejected:
		case NotificationType.ResearchProposalByProfessorRejected:
			return theme.purple;
	}

	return theme.lightGreen;
};

export const buildNotificationTextColor = (notification: NotificationDTO) => {
	switch (notification.type) {
		case NotificationType.CompanyValidationNeeded:
		case NotificationType.RepresentativeNominated:
		case NotificationType.TutorNominated:
		case NotificationType.FacultyCoordinatorNominated:
		case NotificationType.ConventionAcknowledgementNeeded:
		case NotificationType.NotebookValidationNeeded:
		case NotificationType.InternshipDocumentSignatureNeeded:
		case NotificationType.NotebookApproved:
		case NotificationType.CertificateValidationNeeded:
		case NotificationType.CertificateUpdated:
		case NotificationType.CertificateApproved:
		case NotificationType.InternshipEnrollment:
		case NotificationType.PracticeProtocolAdded:
		case NotificationType.CompanyPracticeSupervisorDesignated:
		case NotificationType.CompanyPracticeSupervisorRemoved:
		case NotificationType.SupervisedStudyProgramAdded:
		case NotificationType.SupervisedStudyProgramRemoved:
		case NotificationType.OfferPublished:
		case NotificationType.OfferUpdated:
		case NotificationType.CompanyAdminNominated:
		case NotificationType.EngagementTutorNominated:
		case NotificationType.PossibleInterestInResearchProposal:
		case NotificationType.PossibleInterestInPoliJobsProposal:
			return theme.black;
		case NotificationType.AccordUploaded:
		case NotificationType.AccordValidationNeeded:
		case NotificationType.AccordSignatureNeeded:
		case NotificationType.InternshipChanged:
		case NotificationType.ConventionSignatureNeeded:
		case NotificationType.NotebookSignatureNeeded:
		case NotificationType.NotebookUpdated:
		case NotificationType.CertificateSignatureNeeded:
		case NotificationType.InternshipValidationNeeded:
		case NotificationType.InternshipDeleted:
		case NotificationType.InternshipEnrollmentDeleted:
		case NotificationType.NotebookRejected:
		case NotificationType.FinalNotebookRejected:
		case NotificationType.CertificateRejected:
		case NotificationType.FinalCertificateRejected:
		case NotificationType.InternshipRejected:
		case NotificationType.AccordRejected:
		case NotificationType.FacultyCoordinatorRejected:
		case NotificationType.ProfessorInternshipInvalidated:
		case NotificationType.CompanyInternshipInvalidated:
		case NotificationType.FacultyCoordinatorDesignated:
		case NotificationType.PracticeProtocolChanged:
		case NotificationType.SupervisorUnassigned:
		case NotificationType.CompanyAdminRevoked:
		case NotificationType.PracticeOverdueMarked:
		case NotificationType.PracticeProtocolRejected:
			return theme.white;
		case NotificationType.SkillEndorsed:
		case NotificationType.AppreciationReceived:
		case NotificationType.InternshipApproved:
		case NotificationType.ProfessorJoinedInternship:
		case NotificationType.SupervisorAssigned:
		case NotificationType.ConventionValidated:
		case NotificationType.AccordFinalized:
		case NotificationType.ProfessorInternshipValidated:
		case NotificationType.CompanyInternshipValidated:
		case NotificationType.PracticeOverdueRemoved:
			return theme.white;
		case NotificationType.InternshipStudentApplication:
		case NotificationType.InternshipCompanyAcceptance:
		case NotificationType.InternshipProfessorAcceptance:
		case NotificationType.PoliJobsProposalAccepted:
		case NotificationType.InternshipStudentAcceptance:
		case NotificationType.AccordApproved:
		case NotificationType.FacultyCoordinatorAccepted:
		case NotificationType.PracticeProtocolFinalized:
		case NotificationType.PoliJobsProposalNewApplicant:
			return theme.green;
		case NotificationType.InternshipCompanyRejection:
		case NotificationType.InternshipStudentRejection:
			return theme.white;
		case NotificationType.InternshipProfessorRejection:
		case NotificationType.PoliJobsProposalRejected:
		case NotificationType.PoliJobsProposalCvRejected:
			return theme.white;

		case NotificationType.ResearchProposalByCompanyAccepted:
		case NotificationType.ResearchProposalByProfessorAccepted:
			return theme.green;
		case NotificationType.ResearchProposalByCompanyRejected:
		case NotificationType.ResearchProposalByProfessorRejected:
			return theme.white;
	}

	return theme.green;
};
