import { useState } from "react";
import { Button, Table, Typography, Pagination, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
    openNotification,
    NOTIFICATION_TYPES,
} from "../Notifications/NotificationsUtils";
import {
    getColaborationHistories,
    deleteColaborationHistory,
} from "../../Requests/colaboration-history-requests";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import AddColaborationHistoryModal from "./AddColaborationHistoryModal";
import moment from "moment";
import styles from "./Profile.module.scss";
import { ErrorCodes } from "../../Api";
import { getErrorFromResponse } from "../../utils/responseUtils";
import { getAllFaculties } from "../../Requests/faculty-requests";
import { getFaculties } from "../../utils/reactQueriesConstants";

const ColaborationHistoryTable = (props: { companyId: string }) => {
    const { t } = useTranslation();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [editModalVisibility, setEditModalVisibility] = useState(false);
    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
    const [descriptionModalVisibility, setDescriptionModalVisibility] = useState(false);
    const [selectedColaborationHistory, setSelectedColaborationHistory] =
        useState<{ id: string; description: string } | null>(null);
    const [spinning, setSpinning] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const queryClient = useQueryClient();

    const { data: colaborationHistories, isLoading } = useQuery(
        ["colaborationHistories", props.companyId, currentPage, pageSize],
        () => getColaborationHistories(props.companyId, currentPage, pageSize),
        {
            onError: () => {
                openNotification(
                    t("account.error"),
                    t("account.fetchColaborationHistoriesError"),
                    NOTIFICATION_TYPES.ERROR
                );
            },
        }
    );

    const openGetErrorNotification = (_error: any) => {
        openNotification(
            t("internships.error"),
            t("internships.fetchDataError"),
            NOTIFICATION_TYPES.ERROR
        );
    };

    const { data: allFacultiesList } = useQuery(
        [getFaculties],
        () => getAllFaculties("", 1, 30),
        {
            onError: (err) => {
                openGetErrorNotification(err);
            },
            refetchOnWindowFocus: false,
        }
    );

    const handleDelete = (colaborationHistory: { id: string }) => {
        if (!colaborationHistory || !colaborationHistory.id) {
            openNotification(
                t("account.colaborationHistoryDelete"),
                t("account.colaborationHistoryDeleteError"),
                NOTIFICATION_TYPES.ERROR
            );
        }

        setSpinning(true);

        deleteColaborationHistory(colaborationHistory.id)
            .then(async () => {
                await queryClient.invalidateQueries([
                    "colaborationHistories",
                    props.companyId,
                ]);

                openNotification(
                    t("account.colaborationHistoryDelete"),
                    t("account.colaborationHistoryDeleteSuccess"),
                    NOTIFICATION_TYPES.SUCCESS
                );
            })
            .catch(async (ex: any) => {
                const error = await getErrorFromResponse(ex);

                openNotification(
                    t("account.colaborationHistoryDelete"),
                    error?.code === ErrorCodes.Forbidden
                        ? t("account.noPermissions")
                        : error?.code === ErrorCodes.ColaborationHistoryNotFound
                        ? t("account.colaborationHistoryNotFound")
                        : t("account.colaborationHistoryDeleteError"),
                    NOTIFICATION_TYPES.ERROR
                );
            })
            .finally(() => {
                setSpinning(false);
                setDeleteModalVisibility(false);
            });
    };

    const handleEdit = (colaborationHistory: any) => {
        setSelectedColaborationHistory(colaborationHistory);
        setEditModalVisibility(true);
    };

    const handleCloseEditModal = () => {
        setEditModalVisibility(false);
        setSelectedColaborationHistory(null);
    };

    const handleViewDescription = (colaborationHistory: any) => {
        setSelectedColaborationHistory(colaborationHistory);
        setDescriptionModalVisibility(true);
    };

    const handleCloseDescriptionModal = () => {
        setDescriptionModalVisibility(false);
        setSelectedColaborationHistory(null);
    };

    const columns = [
        {
            title: t("account.colaborationType"),
            dataIndex: "colaborationType",
            key: "colaborationType",
        },
        {
            title: t("account.faculty"),
            dataIndex: "facultyId",
            key: "facultyId",
            render: (facultyId: string) => {
                return allFacultiesList?.find((f) => f.id === facultyId)?.facultyNameRo;
            },
        },
        {
            title: t("account.from"),
            dataIndex: "from",
            key: "from",
            render: (text: string) => moment(text).format("DD.MM.YYYY"),
        },
        {
            title: t("account.to"),
            dataIndex: "to",
            key: "to",
            render: (text: string) => moment(text).format("DD.MM.YYYY"),
        },
        {
            title: t("account.description"),
            dataIndex: "description",
            key: "description",
            render: (_text: string, record: any) => (
                <Button
                    style={{
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        color: theme.black,
                        boxShadow: "none",
                        padding: 0,
                        marginTop: 0,
                    }}
                    onClick={() => handleViewDescription(record)}
                    icon={<FontAwesomeIcon icon={solid("eye")} />}
                    title={t("account.viewDescription")}
                />
            ),
        },
        {
            title: t("account.actions"),
            fixed: "right" as "right",
            width: 85,
            render: (_text: string | undefined, record: any) => (
                <div style={{ whiteSpace: "nowrap" }}>
                    <div className={styles.modalBtnContainer}>
                        <>
                            <div className={styles.modalContainer}>
                                <Button
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        outline: "none",
                                        color: theme.black,
                                        boxShadow: "none",
                                        padding: 0,
                                        marginTop: 0,
                                    }}
                                    onClick={() => handleEdit(record)}
                                    icon={<FontAwesomeIcon icon={solid("edit")} />}
                                    title={t("account.editColaborationHistory")}
                                />
                                <Button
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        outline: "none",
                                        color: theme.black,
                                        boxShadow: "none",
                                        padding: 0,
                                        marginTop: 0,
                                    }}
                                    onClick={() => {
                                        setSelectedColaborationHistory(record);
                                        setDeleteModalVisibility(true);
                                    }}
                                    icon={<FontAwesomeIcon icon={solid("trash")} />}
                                    title={t("account.deleteColaborationHistory")}
                                />
                                <ConfirmationModal
                                    modalText={
                                        t("account.deleteColaborationHistoryMessage") +
                                        record.colaborationType +
                                        "?"
                                    }
                                    handleFunction={() =>
                                        selectedColaborationHistory &&
                                        handleDelete(selectedColaborationHistory)
                                    }
                                    modalVisibility={deleteModalVisibility}
                                    title=""
                                    changeModalVisibility={() => setDeleteModalVisibility(false)}
                                    spinning={spinning}
                                />
                            </div>
                        </>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="px-4 py-10 flex flex-col gap-4">
			<div style={{display: "flex", justifyContent: "space-between"}}>
			<Typography.Title level={2}>{t("account.colaborationHistorys")}</Typography.Title>	
            <Button
                type="primary"
                onClick={() => setModalVisibility(true)}
                style={{ margin: "5px", alignSelf: "self-end" }}
            >
                {t("account.colaborationHistoryAdd")}
            </Button>
			</div>
            <AddColaborationHistoryModal
                companyId={props.companyId}
                onClose={() => setModalVisibility(false)}
                open={modalVisibility}
            />

            <AddColaborationHistoryModal
                companyId={props.companyId}
                editMode={editModalVisibility}
                colaborationHistory={selectedColaborationHistory}
                onClose={handleCloseEditModal}
                open={editModalVisibility}
            />

            <Modal
                open={descriptionModalVisibility}
                onCancel={handleCloseDescriptionModal}
                title={t("account.description")}
                footer={
                    <Button type="primary" onClick={handleCloseDescriptionModal}>
                        {t("account.cancel")}
                    </Button>
                }
            >
                <p style={{whiteSpace: 'pre-line'}}>{selectedColaborationHistory?.description}</p>
            </Modal>

            <div className="flex flex-col gap-10">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <Typography.Title level={3} className="my-0">
                        {colaborationHistories?.totalCount} {t("filtering.results")}
                    </Typography.Title>
                    {!isLoading && (
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={colaborationHistories?.totalCount}
                            onChange={(page, pageSize) => {
                                setCurrentPage(page);
                                setPageSize(pageSize);
                            }}
                            locale={{ items_per_page: t("pagination.items_per_page") }}
                        />
                    )}
                </div>

                <Table
                    locale={{ emptyText: t("tableText.noColaborationHistories") }}
                    columns={columns}
                    dataSource={colaborationHistories?.data ?? []}
                    pagination={false}
                    scroll={{ x: "calc(700px + 50%)", y: 420 }}
                    rowKey={(record) => record.id || ""}
                />
            </div>
        </div>
    );
};

export default ColaborationHistoryTable;