/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ErrorCodes {
	Unknown = "Unknown",
	InternalError = "InternalError",
	Forbidden = "Forbidden",
	InternshipNotFound = "InternshipNotFound",
	AlreadyEnrolled = "AlreadyEnrolled",
	InternshipOverlaps = "InternshipOverlaps",
	HadBachelorsProject = "HadBachelorsProject",
	HadMastersProject = "HadMastersProject",
	CannotApplyAgain = "CannotApplyAgain",
	ApplicationCountExceeded = "ApplicationCountExceeded",
	UserNotFound = "UserNotFound",
	InvalidCredentials = "InvalidCredentials",
	CompanyNotValidated = "CompanyNotValidated",
	NoPositionsAvailable = "NoPositionsAvailable",
	Rejected = "Rejected",
	EmailNotValidated = "EmailNotValidated",
	CompanyAlreadyExists = "CompanyAlreadyExists",
	CompanyUserAlreadyExists = "CompanyUserAlreadyExists",
	IsViewOnly = "IsViewOnly",
	NotRepresentative = "NotRepresentative",
	InternshipEnrollmentNotFound = "InternshipEnrollmentNotFound",
	InvalidEnrollmentStatus = "InvalidEnrollmentStatus",
	MissingStudentGroup = "MissingStudentGroup",
	MissingStudentPersonalData = "MissingStudentPersonalData",
	InvalidStudentGroup = "InvalidStudentGroup",
	ConventionNotFound = "ConventionNotFound",
	StudentNotFound = "StudentNotFound",
	DeanNotFound = "DeanNotFound",
	SupervisorNotFound = "SupervisorNotFound",
	RepresentativeNotFound = "RepresentativeNotFound",
	SupervisionsOngoing = "SupervisionsOngoing",
	CompanyNotFound = "CompanyNotFound",
	FacultyNotFound = "FacultyNotFound",
	FacultyOfInterestAlreadyAllocated = "FacultyOfInterestAlreadyAllocated",
	SupervisorAlreadyAllocated = "SupervisorAlreadyAllocated",
	StudyProgramNotFound = "StudyProgramNotFound",
	MissingStudentStudyProgram = "MissingStudentStudyProgram",
	OtherSupervisorAssigned = "OtherSupervisorAssigned",
	AnafApiUnavailable = "AnafApiUnavailable",
	AlreadyDeleted = "AlreadyDeleted",
	InvalidInternshipType = "InvalidInternshipType",
	InvalidTutor = "InvalidTutor",
	CompanyAlreadyActive = "CompanyAlreadyActive",
	ValidationTokenExpired = "ValidationTokenExpired",
	RefreshTokenExpired = "RefreshTokenExpired",
	RefreshTokenNotFound = "RefreshTokenNotFound",
	OfferNotAvailableForFaculty = "OfferNotAvailableForFaculty",
	OfferNotAvailableForStudyProgram = "OfferNotAvailableForStudyProgram",
	OfferNotAvailableForYear = "OfferNotAvailableForYear",
	DocumentNotFound = "DocumentNotFound",
	OfferArchived = "OfferArchived",
	NotCompanyOffer = "NotCompanyOffer",
	NotProfessorOffer = "NotProfessorOffer",
	PracticeCertificateAlreadyExists = "PracticeCertificateAlreadyExists",
	PracticeCertificateNotFound = "PracticeCertificateNotFound",
	BadRequest = "BadRequest",
	ColaborationHistoryNotFound = "ColaborationHistoryNotFound",
}

export function ErrorCodesFromJSON(json: any): ErrorCodes {
	return ErrorCodesFromJSONTyped(json, false);
}

export function ErrorCodesFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ErrorCodes {
	return json as ErrorCodes;
}

export function ErrorCodesToJSON(value?: ErrorCodes | null): any {
	return value as any;
}
