import { Form, Row, Col, Button } from "antd";
import Layout from "../../Containers/Layout";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import CustomForm from "../../CustomComponents/CustomForm";
import styles from "./Feedback.module.scss";
import { theme } from "../../theme";
import Question from "./Question";
import { FormTypeEnum, QuestionTypeEnum } from "../../Api";
import { useQuery } from "react-query";
import {
	addAnswer,
	getQuestionsByForm,
} from "../../Requests/feedback-requests";
import {
	openNotification,
	NOTIFICATION_TYPES,
} from "../Notifications/NotificationsUtils";
import { AnswerAddDTO } from "../../Api/models/AnswerAddDTO";
import CustomButton from "../../CustomComponents/CustomButton";
import ConfirmationModal from "../../Containers/ConfirmationModal";

const ProfesorsAutoEvaluationForm = () => {
	const { t } = i18n;

	const formType = FormTypeEnum.ProfessorForm;
	const [confirmationModalVisibility, setConfirmationModalVisibility] =
		useState(false);
	const [redirect, setRedirect] = useState(false);

	const [form] = Form.useForm<{
		[key: string]: string | null;
		q1: string | null;
		q2: string | null;
		q3: string | null;
		q4: string | null;
		q5: string | null;
		q6: string | null;
		q7: string | null;
	}>();

	const openFetchError = (_error: any) => {
		openNotification(
			"Eroare!",
			"Datele nu au putut fi aduse din server!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveError = (_error: any) => {
		openNotification(
			"Eroare!",
			"Raspunsurile nu au putut fi salvate!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveSuccess = (_message: any) => {
		openNotification(
			"Info!",
			"Raspunsurile au fost salvate!",
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const openMissingAnswers = (_message: any) => {
		openNotification(
			"Eroare!",
			"Nu toate intrebarile au fost completate!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data } = useQuery(
		["getQuestionsProfesors", formType],
		() => {
			return getQuestionsByForm(formType);
		},
		{
			onError: openFetchError,
		}
	);

	const validateForm = async () => {
		try {
			await form.validateFields();
			handleSave();
		} catch (error) {
			openMissingAnswers(error);
		}
	};

	const handleSave = () => {
		const state = form.getFieldsValue();
		const answerAddDTOArray: AnswerAddDTO[] = [];

		data?.forEach((question) => {
			const questionName = question.name!;
			const questionId = question.questionId;

			if (state.hasOwnProperty(questionName)) {
				const answerAddDTO = {
					questionId,
					answers: [state[questionName]?.toString() ?? ""],
				};
				answerAddDTOArray.push(answerAddDTO);
			}
		});

		addAnswer(answerAddDTOArray, formType)
			.then(() => {
				openSaveSuccess("");
				setRedirect(true);
			})
			.catch(openSaveError);
	};

	if (redirect) {
		return <Redirect to="/" />;
	} else {
		return (
			<Layout>
				<div className="text-2xl text-center" style={{ marginTop: "30px" }}>
					<label>CHESTIONAR PRIVIND AUTOEVALUAREA CADRELOR DIDACTICE</label>
				</div>
				<div className="container mx-auto px-4 py-10">
					<Form form={form} layout={theme.layout}>
						<label key="q1" className="text-lg">
							Activitatea didactică
							<Form.Item
								name="q1"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.1. Prezint în mod clar ce vor învăța studenții în cadrul lecției respective, pentru a le oferi o direcție precisă.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q2" className="text-lg">
							<Form.Item
								name="q2"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.2. Organizez conținutul lecției într-un mod coerent și progresiv, pentru ca studenții să poată urmări și înțelege ușor noțiunile prezentate.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q3" className="text-lg">
							<Form.Item
								name="q3"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.3. Selectez conținutul cursului astfel încât să fie valoros și adecvat pentru disciplina predată.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q4" className="text-lg">
							<Form.Item
								name="q4"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.4. Prezint motivație intrinsecă și o familiaritate profundă pentru subiectul pe care-l predau și o împărtășesc cu studenții.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q5" className="text-lg">
							<Form.Item
								name="q5"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.5. Reușesc să stimulez interesul studenților pentru disciplină, evitând transmiterea de informații lipsite de sens, fără a trezi imaginația.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q6" className="text-lg">
							<Form.Item
								name="q6"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.6. Folosesc o evaluare adecvată și onestă, evitând subiectivismul.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q7" className="text-lg">
							<Form.Item
								name="q7"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.7. Integrez instrumente moderne (smartboard, aplicații, platforme online, etc.) pentru a diversifica predarea.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q8" className="text-lg">
							Învățare centrată pe student
							<Form.Item
								name="q8"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.1. Îmi adaptez stilul de predare în funcție de feedback-ul cursantului și răspund nevoilor acestuia.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q9" className="text-lg">
							<Form.Item
								name="q9"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.2. Încurajez independența studenților prin crearea unui mediu de învățare autonom, învățare prin descoperire, promovarea luării de decizii care conduc la dezvoltarea încrederii în sine.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q10" className="text-lg">
							<Form.Item
								name="q10"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.3. Prezint o preocupare aparte pentru studenți oferind un feedback de calitate asupra proiectelor, temelor de casă, care inspiră și motivează studenții, evitând criticile distructive.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q11" className="text-lg">
							<Form.Item
								name="q11"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.4. Utilizez limbajul verbal și nonverbal potrivit cu studenții.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q12" className="text-lg">
							<Form.Item
								name="q12"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.5. Manifest empatie și respect față de studenți, înțelegându-le emoțiile.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q13" className="text-lg">
							<Form.Item
								name="q13"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.6. Folosesc metode diversificate de predare care ajută la stimularea gândirii critice și la implicarea activă a studenților în procesul de învățare.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q14" className="text-lg">
							<Form.Item
								name="q14"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.7. Prezint reactivitate la comunicările studenților, răspunzând prompt și în mod eficient la e-mailuri, mesaje sau întrebări pe platformă, aceasta ajutând la menținerea unui flux de învățare continuu și fără întreruperi.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q15" className="text-lg">
							Cercetare și publicare
							<Form.Item
								name="q15"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.1. Public în mod constant lucrări de cercetare în reviste naționale și internaționale recunoscute.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q16" className="text-lg">
							<Form.Item
								name="q16"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.2. Particip la conferințe, seminarii și alte evenimente științifice pentru a împărtăși cunoștințe și a învăța de la alții.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q17" className="text-lg">
							<Form.Item
								name="q17"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.3. Integrez studenții în activități de cercetare și proiecte colaborative pentru a dezvolta competențele acestora.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q18" className="text-lg">
							<Form.Item
								name="q18"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.4. Identific și aplic pentru granturi de cercetare relevante pentru disciplina mea.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q19" className="text-lg">
							<Form.Item
								name="q19"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.5. Public lucrări de cercetare în colaborare cu alți autori pentru a crește vizibilitatea academică.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q20" className="text-lg">
							<Form.Item
								name="q20"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.6. Folosesc resursele universității pentru a sprijini activitățile de cercetare (laboratoare, biblioteci, baze de date).",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q21" className="text-lg">
							<Form.Item
								name="q21"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"3.7. Mă asigur că rezultatele cercetării mele sunt diseminate în mod eficient prin publicare, prelegeri și evenimente.",
										answers: [],
										answerType: "timeSpan",
									}}
								/>
							</Form.Item>
						</label>
					</Form>
					<div className="flex justify-center">
						<Button type="primary" onClick={validateForm}>
							Salveaza
						</Button>
					</div>
					<ConfirmationModal
						modalText={t("feedback.submit")}
						handleFunction={() => handleSave()}
						modalVisibility={confirmationModalVisibility}
						title=""
						changeModalVisibility={() => setConfirmationModalVisibility(false)}
						spinning={false}
					/>
				</div>
			</Layout>
		);
	}
};

export default ProfesorsAutoEvaluationForm;
