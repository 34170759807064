import { Form, Row, Col, Button } from "antd";
import Layout from "../../Containers/Layout";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import CustomForm from "../../CustomComponents/CustomForm";
import styles from "./Feedback.module.scss";
import { theme } from "../../theme";
import Question from "./Question";
import { FormTypeEnum, QuestionTypeEnum } from "../../Api";
import { useQuery } from "react-query";
import {
	addAnswer,
	getQuestionsByForm,
} from "../../Requests/feedback-requests";
import {
	openNotification,
	NOTIFICATION_TYPES,
} from "../Notifications/NotificationsUtils";
import { AnswerAddDTO } from "../../Api/models/AnswerAddDTO";
import CustomButton from "../../CustomComponents/CustomButton";
import ConfirmationModal from "../../Containers/ConfirmationModal";

const ProfesorsEvaluationForm = () => {
	const { t } = i18n;

	const formType = FormTypeEnum.ProfessorForm;
	const [confirmationModalVisibility, setConfirmationModalVisibility] =
		useState(false);
	const [redirect, setRedirect] = useState(false);

	const [form] = Form.useForm<{
		[key: string]: string | null;
		q1: string | null;
		q2: string | null;
		q3: string | null;
		q4: string | null;
		q5: string | null;
		q6: string | null;
		q7: string | null;
	}>();

	const openFetchError = (_error: any) => {
		openNotification(
			"Eroare!",
			"Datele nu au putut fi aduse din server!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveError = (_error: any) => {
		openNotification(
			"Eroare!",
			"Raspunsurile nu au putut fi salvate!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const openSaveSuccess = (_message: any) => {
		openNotification(
			"Info!",
			"Raspunsurile au fost salvate!",
			NOTIFICATION_TYPES.SUCCESS
		);
	};

	const openMissingAnswers = (_message: any) => {
		openNotification(
			"Eroare!",
			"Nu toate intrebarile au fost completate!",
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data } = useQuery(
		["getQuestionsProfesors", formType],
		() => {
			return getQuestionsByForm(formType);
		},
		{
			onError: openFetchError,
		}
	);

	const validateForm = async () => {
		try {
			await form.validateFields();
			handleSave();
		} catch (error) {
			openMissingAnswers(error);
		}
	};

	const handleSave = () => {
		const state = form.getFieldsValue();
		const answerAddDTOArray: AnswerAddDTO[] = [];

		data?.forEach((question) => {
			const questionName = question.name!;
			const questionId = question.questionId;

			if (state.hasOwnProperty(questionName)) {
				const answerAddDTO = {
					questionId,
					answers: [state[questionName]?.toString() ?? ""],
				};
				answerAddDTOArray.push(answerAddDTO);
			}
		});

		addAnswer(answerAddDTOArray, formType)
			.then(() => {
				openSaveSuccess("");
				setRedirect(true);
			})
			.catch(openSaveError);
	};

	if (redirect) {
		return <Redirect to="/" />;
	} else {
		return (
			<Layout>
				<div className="text-2xl text-center" style={{ marginTop: "30px" }}>
					<label>
						CHESTIONAR PRIVIND EVALUAREA ACTIVITĂȚII DIDACTICE DE CĂTRE STUDENȚI
					</label>
				</div>
				<div className="container mx-auto px-4 py-10">
					<Form form={form} layout={theme.layout}>
						<label key="q1" className="text-lg">
							Date generale despre disciplină
							<Form.Item
								name="q1"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.1. Calificarea obținută prin absolvirea programului de studii universitare la care sunteți înmatriculat",
										answers: [],
										answerType: "oportun",
									}}
								/>
							</Form.Item>
						</label>
						<label key="q2" className="text-lg">
							<Form.Item
								name="q2"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.2. Competențele necesare inserției dumneavoastră pe piața muncii",
										answers: [],
										answerType: "oportun",
									}}
								/>
							</Form.Item>
						</label>
						<label key="q3" className="text-lg">
							<Form.Item
								name="q3"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question: "1.3. Pentru activitățile de curs",
										answers: [],
										answerType: "suficient",
									}}
								/>
							</Form.Item>
						</label>
						<label key="q4" className="text-lg">
							<Form.Item
								name="q4"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"1.4. Pentru activitățile practice (seminare/ laborator/ proiect)",
										answers: [],
										answerType: "suficient",
									}}
								/>
							</Form.Item>
						</label>

						<label key="q5" className="text-lg">
							Date despre activitatea didactică a titularului la curs
							<Form.Item
								name="q5"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.1. Selectarea conținuturilor în raport cu obiectivele disciplinei",
										answers: [],
										type: QuestionTypeEnum.LikertSatisfaction,
									}}
								/>
							</Form.Item>
						</label>
						<label key="q6" className="text-lg">
							<Form.Item
								name="q6"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.2. Relevanța informațiilor prezentate în raport cu cerințele pieței muncii",
										answers: [],
										type: QuestionTypeEnum.LikertSatisfaction,
									}}
								/>
							</Form.Item>
						</label>
						<label key="q7" className="text-lg">
							<Form.Item
								name="q7"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.3. Corelarea noțiunilor cu conținuturile altor discipline studiate",
										answers: [],
										type: QuestionTypeEnum.LikertSatisfaction,
									}}
								/>
							</Form.Item>
						</label>
						<label key="q8" className="text-lg">
							<Form.Item
								name="q8"
								rules={[
									{
										required: true,
										message: "Raspuns obligatoriu!",
										whitespace: true,
									},
								]}
							>
								<Question
									props={{
										question:
											"2.4. Calitatea mijloacelor didactice utilizate (suport de curs, bibliografie de specialitate etc.)",
										answers: [],
										type: QuestionTypeEnum.LikertSatisfaction,
									}}
								/>
							</Form.Item>
						</label>
					</Form>
					<div className="flex justify-center">
						<Button type="primary" onClick={validateForm}>
							Salveaza
						</Button>
					</div>
					<ConfirmationModal
						modalText={t("feedback.submit")}
						handleFunction={() => handleSave()}
						modalVisibility={confirmationModalVisibility}
						title=""
						changeModalVisibility={() => setConfirmationModalVisibility(false)}
						spinning={false}
					/>
				</div>
			</Layout>
		);
	}
};

export default ProfesorsEvaluationForm;
