/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
	AdminDTO,
	AdminDTOFromJSON,
	AdminDTOToJSON,
	ColaborationHistoryDTO,
	ColaborationHistoryDTOFromJSON,
	ColaborationHistoryDTOToJSON,
	ColaborationHistoryDTOPagedResponse,
	ColaborationHistoryDTOPagedResponseFromJSON,
	ColaborationHistoryDTOPagedResponseToJSON,
} from "../models";

export interface ApiColaborationHistoryAddColaborationHistoryPostRequest {
	colaborationHistoryDTO?: ColaborationHistoryDTO;
}

export interface ApiColaborationHistoryDeleteIdDeleteRequest {
	id: string;
}

export interface ApiColaborationHistoryGetAllColaborationsHistoryGetRequest {
	namePattern?: string;
	colaborationType?: string;
	description?: string;
	from?: Date;
	to?: Date;
	facultyId?: string;
	companyId?: string;
	page?: number;
	pageSize?: number;
}

export interface ApiColaborationHistoryGetColaborationHistoryByIdIdGetRequest {
	id: string;
}

export interface ApiColaborationHistoryUpdateColaborationHistoryIdPutRequest {
	id: string;
	colaborationHistoryDTO?: ColaborationHistoryDTO;
}

/**
 *
 */
export class ColaborationHistoryApi extends runtime.BaseAPI {
	/**
	 */
	async apiColaborationHistoryAddColaborationHistoryPostRaw(
		requestParameters: ApiColaborationHistoryAddColaborationHistoryPostRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<ColaborationHistoryDTO>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ColaborationHistory/AddColaborationHistory`,
				method: "POST",
				headers: headerParameters,
				query: queryParameters,
				body: ColaborationHistoryDTOToJSON(
					requestParameters.colaborationHistoryDTO
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ColaborationHistoryDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiColaborationHistoryAddColaborationHistoryPost(
		requestParameters: ApiColaborationHistoryAddColaborationHistoryPostRequest = {},
		initOverrides?: RequestInit
	): Promise<ColaborationHistoryDTO> {
		const response =
			await this.apiColaborationHistoryAddColaborationHistoryPostRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiColaborationHistoryDeleteIdDeleteRaw(
		requestParameters: ApiColaborationHistoryDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<void>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiColaborationHistoryDeleteIdDelete."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ColaborationHistory/Delete/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "DELETE",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async apiColaborationHistoryDeleteIdDelete(
		requestParameters: ApiColaborationHistoryDeleteIdDeleteRequest,
		initOverrides?: RequestInit
	): Promise<void> {
		await this.apiColaborationHistoryDeleteIdDeleteRaw(
			requestParameters,
			initOverrides
		);
	}

	/**
	 */
	async apiColaborationHistoryGetAllColaborationsHistoryGetRaw(
		requestParameters: ApiColaborationHistoryGetAllColaborationsHistoryGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<ColaborationHistoryDTOPagedResponse>> {
		const queryParameters: any = {};

		if (requestParameters.namePattern !== undefined) {
			queryParameters["NamePattern"] = requestParameters.namePattern;
		}

		if (requestParameters.colaborationType !== undefined) {
			queryParameters["ColaborationType"] = requestParameters.colaborationType;
		}

		if (requestParameters.description !== undefined) {
			queryParameters["Description"] = requestParameters.description;
		}

		if (requestParameters.from !== undefined) {
			queryParameters["From"] = (requestParameters.from as any).toISOString();
		}

		if (requestParameters.to !== undefined) {
			queryParameters["To"] = (requestParameters.to as any).toISOString();
		}

		if (requestParameters.facultyId !== undefined) {
			queryParameters["FacultyId"] = requestParameters.facultyId;
		}

		if (requestParameters.companyId !== undefined) {
			queryParameters["CompanyId"] = requestParameters.companyId;
		}

		if (requestParameters.page !== undefined) {
			queryParameters["Page"] = requestParameters.page;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters["PageSize"] = requestParameters.pageSize;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ColaborationHistory/GetAllColaborationsHistory`,
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ColaborationHistoryDTOPagedResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiColaborationHistoryGetAllColaborationsHistoryGet(
		requestParameters: ApiColaborationHistoryGetAllColaborationsHistoryGetRequest = {},
		initOverrides?: RequestInit
	): Promise<ColaborationHistoryDTOPagedResponse> {
		const response =
			await this.apiColaborationHistoryGetAllColaborationsHistoryGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiColaborationHistoryGetColaborationHistoryByIdIdGetRaw(
		requestParameters: ApiColaborationHistoryGetColaborationHistoryByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<ColaborationHistoryDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiColaborationHistoryGetColaborationHistoryByIdIdGet."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ColaborationHistory/GetColaborationHistoryById/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "GET",
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ColaborationHistoryDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiColaborationHistoryGetColaborationHistoryByIdIdGet(
		requestParameters: ApiColaborationHistoryGetColaborationHistoryByIdIdGetRequest,
		initOverrides?: RequestInit
	): Promise<ColaborationHistoryDTO> {
		const response =
			await this.apiColaborationHistoryGetColaborationHistoryByIdIdGetRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}

	/**
	 */
	async apiColaborationHistoryUpdateColaborationHistoryIdPutRaw(
		requestParameters: ApiColaborationHistoryUpdateColaborationHistoryIdPutRequest,
		initOverrides?: RequestInit
	): Promise<runtime.ApiResponse<AdminDTO>> {
		if (requestParameters.id === null || requestParameters.id === undefined) {
			throw new runtime.RequiredError(
				"id",
				"Required parameter requestParameters.id was null or undefined when calling apiColaborationHistoryUpdateColaborationHistoryIdPut."
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters["Content-Type"] = "application/json";

		if (this.configuration && this.configuration.apiKey) {
			headerParameters["Authorization"] =
				this.configuration.apiKey("Authorization"); // Bearer authentication
		}

		const response = await this.request(
			{
				path: `/api/ColaborationHistory/UpdateColaborationHistory/{id}`.replace(
					`{${"id"}}`,
					encodeURIComponent(String(requestParameters.id))
				),
				method: "PUT",
				headers: headerParameters,
				query: queryParameters,
				body: ColaborationHistoryDTOToJSON(
					requestParameters.colaborationHistoryDTO
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AdminDTOFromJSON(jsonValue)
		);
	}

	/**
	 */
	async apiColaborationHistoryUpdateColaborationHistoryIdPut(
		requestParameters: ApiColaborationHistoryUpdateColaborationHistoryIdPutRequest,
		initOverrides?: RequestInit
	): Promise<AdminDTO> {
		const response =
			await this.apiColaborationHistoryUpdateColaborationHistoryIdPutRaw(
				requestParameters,
				initOverrides
			);
		return await response.value();
	}
}
