import { Avatar, Button, Pagination, Spin, Typography } from "antd";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isUserLogged } from "../../utils/utilFunctions";
import {
	NOTIFICATION_TYPES,
	openNotification,
} from "../Notifications/NotificationsUtils";
import {
	EnrollmentStatus,
	ErrorCodes,
	InternshipEngagementType,
	InternshipType,
	Role,
} from "../../Api";
import {
	accept,
	cancel,
	getAllApplications,
} from "../../Requests/internship-requests";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import InternshipActions from "./InternshipActions";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient } from "react-query";
import {
	getAllApplicationTypesCount,
	getApplicationsData,
} from "../../utils/reactQueriesConstants";
import { getErrorFromResponse } from "../../utils/responseUtils";

const StudentInternships = (props: any) => {
	const { t } = useTranslation();
	const [pageSize, setPageSize] = useState(12);
	const [currentPage, setCurrent] = useState(1);
	const [selectedInternshipId, setSelectedInternshipId] = useState<string>("");
	const location = useLocation();
	const queryClient = useQueryClient();
	const [acceptInternshipModalVisibility, setAcceptInternshipModalVisibility] =
		useState(false);
	const [
		cancelApplicationModalVisibility,
		setCancelApplicationModalVisibility,
	] = useState(false);
	const [spinning, setSpinning] = useState(false);

	const openGetApplicationsErrorNotification = (_error: any) => {
		openNotification(
			t("internships.error"),
			t("internships.fetchApplicationsError"),
			NOTIFICATION_TYPES.ERROR
		);
	};

	const { data, isLoading: loading } = useQuery(
		[getApplicationsData, currentPage, pageSize, props.location.pathname],
		() => {
			if (props.location.pathname === "/aplicarile-mele") {
				return getAllApplications(
					[],
					[],
					[],
					[],
					"",
					[EnrollmentStatus.Waiting],
					undefined,
					currentPage,
					pageSize
				);
			} else if (props.location.pathname === "/bursele-mele") {
				return getAllApplications(
					[],
					[],
					[InternshipEngagementType.Scholarship],
					[],
					"",
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
			} else {
				return getAllApplications(
					[],
					[],
					[
						InternshipEngagementType.Job,
						InternshipEngagementType.ResearchInternship,
					],
					[],
					"",
					[EnrollmentStatus.Accepted],
					[EnrollmentStatus.Accepted],
					currentPage,
					pageSize
				);
			}
		},
		{
			onError: openGetApplicationsErrorNotification,
		}
	);

	const handleListChange = (current: number, pageSize: number) => {
		setCurrent(() => current);
		setPageSize(() => pageSize);
	};

	const cancelApplication = (internshipId: string) => {
		setSpinning(true);

		cancel(internshipId)
			.then(async () => {
				await queryClient.invalidateQueries(getApplicationsData);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("internships.withdrawApplication"),
					t("internships.applicationWithdrawalSuccessMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch((_error) => {
				console.error(_error);

				openNotification(
					t("internships.withdrawApplication"),
					t("internships.applicationWithdrawalErrorMessage"),
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setCancelApplicationModalVisibility(false);
			});
	};

	const acceptOffer = (internshipId: string) => {
		setSpinning(true);

		accept(internshipId)
			.then(async () => {
				//dispatch(studentInternshipEnrollmentIdSet(item.internshipId));
				await queryClient.invalidateQueries(getApplicationsData);
				await queryClient.invalidateQueries(getAllApplicationTypesCount);
				openNotification(
					t("internships.acceptOffer"),
					t("internships.acceptOfferSuccessMessage"),
					NOTIFICATION_TYPES.SUCCESS
				);
			})
			.catch(async (error) => {
				const errorMessage = await getErrorFromResponse(error);

				let notificationMessage;

				switch (errorMessage?.code) {
					case ErrorCodes.InternshipEnrollmentNotFound:
						notificationMessage = t("internships.enrollmentNotFound");
						break;
					case ErrorCodes.InvalidEnrollmentStatus:
						notificationMessage = t("internships.invalidEnrollmentStatus");
						break;
					case ErrorCodes.MissingStudentGroup:
						notificationMessage = t("internships.groupMissing");
						break;
					case ErrorCodes.InvalidStudentGroup:
						notificationMessage = t("internships.invalidGroup");
						break;
					case ErrorCodes.MissingStudentStudyProgram:
						notificationMessage = t("internships.studyProgramMissing");
						break;
					case ErrorCodes.MissingStudentPersonalData:
						notificationMessage = t("internships.personalDataMissing");
						break;
					default:
						notificationMessage = t("internships.acceptOfferErrorMessage");
						break;
				}

				openNotification(
					t("internships.acceptOffer"),
					notificationMessage,
					NOTIFICATION_TYPES.ERROR
				);
			})
			.finally(() => {
				setSpinning(false);
				setAcceptInternshipModalVisibility(false);
			});
	};

	return (
		<Spin size="large" spinning={loading}>
			<div className="px-4 py-10 flex flex-col gap-10">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Typography.Title level={3} className="my-0">
						{data?.totalCount} {t("filtering.results")}
					</Typography.Title>
					{!loading && (
						<Pagination
							current={currentPage}
							pageSize={pageSize}
							total={data?.totalCount}
							onChange={handleListChange}
							locale={{ items_per_page: t("pagination") }}
							pageSizeOptions={[12, 24, 48, 96]}
							defaultPageSize={12}
							hideOnSinglePage={
								data?.totalCount ? data.totalCount <= pageSize : true
							}
						/>
					)}
				</div>

				{loading && !data && (
					<div className="w-full py-10 text-center">Loading</div>
				)}
				{data?.data?.length === 0 && (
					<div className="w-full py-10 text-center">
						{t("tableText.noApplications")}
					</div>
				)}

				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-4">
					{data?.data?.map((item: any) => (
						<div
							className="bg-white rounded-md shadow-md p-4 flex flex-col gap-4"
							key={item.id}
						>
							<div className="flex flex-col gap-2">
								<Link
									to={{
										pathname: `/profil`,
										state: {
											id: item.companyName ? item.companyId : item.professorId,
											type: item.companyName
												? InternshipType.ByCompany
												: InternshipType.ByProfessor,
											userType: item.companyName
												? Role.Company
												: Role.Professor,
											origin: location.pathname,
										},
									}}
								>
									<Avatar src={item.companyLogo || item.professorHeadshot} />
									<span className="text-secondary">
										{item.companyName || item.professorName}
									</span>
								</Link>

								<Link
									to={{
										pathname: "/propunere/" + item.id,
										search:
											"?type=" +
											(item.companyName
												? InternshipType.ByCompany
												: InternshipType.ByProfessor),
										state: {
											id: item.id,
											type: item.companyName
												? InternshipType.ByCompany
												: InternshipType.ByProfessor,
											origin: location.pathname,
										},
									}}
									className="text-lg text-secondary font-bold flex items-center gap-2 line-clamp-2 overflow-ellipsis"
								>
									<FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />

									{item.internshipName}
								</Link>
							</div>

							{item.skillsRequired?.length > 0 && (
								<div className="flex flex-wrap gap-1 items-center">
									{item.skillsRequired?.map((skill: any) => (
										<span
											className="bg-primary rounded-md px-2 py-1 text-white"
											key={skill}
										>
											{skill}
										</span>
									))}
								</div>
							)}

							<div className="line-clamp-5 overflow-ellipsis">
								{item.shortDescription}
							</div>

							<InternshipActions item={item} activeTab={""} />

							<div className="mt-auto flex flex-col gap-2 w-full">
								{isUserLogged() && (
									<>
										{item.recruiterStatus === EnrollmentStatus.Accepted &&
										item.studentStatus !== EnrollmentStatus.Accepted ? (
											<>
												<Button
													onClick={() => {
														setSelectedInternshipId(item.internshipId);
														setAcceptInternshipModalVisibility(true);
													}}
													title={t("internships.acceptInternshipOffer")}
													icon={
														<FontAwesomeIcon icon={solid("circle-check")} />
													}
												>
													{t("internships.acceptInternshipOffer")}
												</Button>

												<ConfirmationModal
													modalText={t("internships.acceptInternshipMessage")}
													handleFunction={() =>
														acceptOffer(selectedInternshipId)
													}
													modalVisibility={acceptInternshipModalVisibility}
													changeModalVisibility={() =>
														setAcceptInternshipModalVisibility(false)
													}
													title=""
													spinning={spinning}
												/>
											</>
										) : null}

										{item.studentStatus !== EnrollmentStatus.Accepted ? (
											<>
												<Button
													danger
													onClick={() => {
														setSelectedInternshipId(item.internshipId);
														setCancelApplicationModalVisibility(true);
													}}
													title={t("internships.reject")}
													icon={<FontAwesomeIcon icon={solid("ban")} />}
												>
													{t("internships.reject")}
												</Button>

												<ConfirmationModal
													modalText={t("internships.withdrawInternshipMessage") +
														t("internships.withdrawInternshipMessage2")}
													handleFunction={() =>
														cancelApplication(selectedInternshipId)
													}
													modalVisibility={cancelApplicationModalVisibility}
													title=""
													changeModalVisibility={() =>
														setCancelApplicationModalVisibility(false)
													}
													spinning={spinning}
												/>
											</>
										) : null}
									</>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</Spin>
	);
};

export default StudentInternships;
