/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
	Company,
	CompanyFromJSON,
	CompanyFromJSONTyped,
	CompanyToJSON,
} from "./Company";
import {
	Faculty,
	FacultyFromJSON,
	FacultyFromJSONTyped,
	FacultyToJSON,
} from "./Faculty";

/**
 *
 * @export
 * @interface ColaborationHistory
 */
export interface ColaborationHistory {
	/**
	 *
	 * @type {string}
	 * @memberof ColaborationHistory
	 */
	id?: string;
	/**
	 *
	 * @type {Date}
	 * @memberof ColaborationHistory
	 */
	createdAt?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ColaborationHistory
	 */
	updatedAt?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof ColaborationHistory
	 */
	colaborationType?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof ColaborationHistory
	 */
	description?: string | null;
	/**
	 *
	 * @type {Date}
	 * @memberof ColaborationHistory
	 */
	from?: Date;
	/**
	 *
	 * @type {Date}
	 * @memberof ColaborationHistory
	 */
	to?: Date;
	/**
	 *
	 * @type {string}
	 * @memberof ColaborationHistory
	 */
	facultyId?: string;
	/**
	 *
	 * @type {Faculty}
	 * @memberof ColaborationHistory
	 */
	faculty?: Faculty;
	/**
	 *
	 * @type {string}
	 * @memberof ColaborationHistory
	 */
	companyId?: string;
	/**
	 *
	 * @type {Company}
	 * @memberof ColaborationHistory
	 */
	company?: Company;
}

export function ColaborationHistoryFromJSON(json: any): ColaborationHistory {
	return ColaborationHistoryFromJSONTyped(json, false);
}

export function ColaborationHistoryFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): ColaborationHistory {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: !exists(json, "id") ? undefined : json["id"],
		createdAt: !exists(json, "createdAt")
			? undefined
			: new Date(json["createdAt"]),
		updatedAt: !exists(json, "updatedAt")
			? undefined
			: new Date(json["updatedAt"]),
		colaborationType: !exists(json, "colaborationType")
			? undefined
			: json["colaborationType"],
		description: !exists(json, "description") ? undefined : json["description"],
		from: !exists(json, "from") ? undefined : new Date(json["from"]),
		to: !exists(json, "to") ? undefined : new Date(json["to"]),
		facultyId: !exists(json, "facultyId") ? undefined : json["facultyId"],
		faculty: !exists(json, "faculty")
			? undefined
			: FacultyFromJSON(json["faculty"]),
		companyId: !exists(json, "companyId") ? undefined : json["companyId"],
		company: !exists(json, "company")
			? undefined
			: CompanyFromJSON(json["company"]),
	};
}

export function ColaborationHistoryToJSON(
	value?: ColaborationHistory | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		createdAt:
			value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
		updatedAt:
			value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
		colaborationType: value.colaborationType,
		description: value.description,
		from: value.from === undefined ? undefined : value.from.toISOString(),
		to: value.to === undefined ? undefined : value.to.toISOString(),
		facultyId: value.facultyId,
		faculty: FacultyToJSON(value.faculty),
		companyId: value.companyId,
		company: CompanyToJSON(value.company),
	};
}
