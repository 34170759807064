import { ColaborationHistoryApi, ColaborationHistoryDTO } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthColaborationHistoryApiFactory = () =>
	new ColaborationHistoryApi(getAuthConfiguration());

export const getColaborationHistoriesById = (id: string) => {
	return AuthColaborationHistoryApiFactory().apiColaborationHistoryGetColaborationHistoryByIdIdGet(
		{ id }
	);
};

export const getColaborationHistories = (
	companyId: string,
	page: number,
	pageSize: number
) => {
	return AuthColaborationHistoryApiFactory().apiColaborationHistoryGetAllColaborationsHistoryGet(
		{ companyId, page, pageSize }
	);
};

export const addColaborationHistory = async (
	colaborationHistoryDTO: ColaborationHistoryDTO
) => {
	await AuthColaborationHistoryApiFactory().apiColaborationHistoryAddColaborationHistoryPost(
		{ colaborationHistoryDTO }
	);
};

export const updateColaborationHistory = async (
	id: string,
	colaborationHistoryDTO: ColaborationHistoryDTO
) => {
	await AuthColaborationHistoryApiFactory().apiColaborationHistoryUpdateColaborationHistoryIdPut(
		{ id, colaborationHistoryDTO }
	);
};

export const deleteColaborationHistory = async (id: string) => {
	await AuthColaborationHistoryApiFactory().apiColaborationHistoryDeleteIdDelete(
		{ id }
	);
};
